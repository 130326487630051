import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { useIsMutating } from "@tanstack/react-query";

export default function IsMutationProvider({ children }) {
  const isMutation = useIsMutating();

  useEffect(() => {
    if (isMutation) {
      notify();
    }
  }, [isMutation]);

  const notify = () =>
    toast.loading("Loading", {
      toastId: "mutation-key",
      autoClose: false,
      className: "toast-message",
      closeButton: null,
    });

  return <>{children}</>;
}
