import axios from "axios";
import { toast } from "react-toastify";
import { toastOptions } from "../providers/ToastNotificationProvider";
import { ExclamationOctagon } from "react-bootstrap-icons";

function isValidUrl(url) {
  const pattern = /^(?:\w+:)?\/\/([^\s.]+\.\S{2}|localhost[:?\d]*)\S*$/;
  return pattern.test(url);
}

const blockListUrlForNotification = ["localhost:6868"];

let instance = axios.create({
  baseURL: process.env.REACT_APP_TEMP_BASE_URL,
});

function uuid() {
  return (Math.random() + 1).toString(16).substring(2);
}

function token() {
  if (localStorage.getItem("access_token")) {
    let token = localStorage.getItem("access_token");
    return token;
  }
  return null;
}

instance.interceptors.request.use((config) => {
  const _token = token();
  if (_token) {
    config.headers.Authorization = `Bearer ${_token}`;
  }
  return config;
});

instance.interceptors.response.use(
  (response) => {
    if (response.data.message) {
      if (toast.isActive("mutation-key")) {
        setTimeout(() => {
          toast.update("mutation-key", {
            ...toastOptions,
            isLoading: false,
            toastId: uuid(),
            render: response.data.message,
          });
        }, 200);
      } else {
        toast.dismiss();
        toast.success(response.data.message, {
          ...toastOptions,
          className: "toast-message",
        });
      }
      return response.data;
    }
    toast.dismiss();
    return response.data;
  },
  (error) => {
    let message = null;

    if (error.config?.url && isValidUrl(error.config.url)) {
      const url = new URL(error.config.url);

      if (blockListUrlForNotification.includes(url.host)) {
        throw error;
      }
    }

    if (error.response?.status === 401) {
      localStorage.removeItem("auth_user");
      window.location.reload();
    } else if (error.response?.data?.error_description) {
      message = error.response.data.error_description;
    } else if (error.response?.data?.message) {
      message = error.response?.data?.message;
    } else if (error.response?.data?.error) {
      message = error.response?.data?.error;
    } else if (error.response?.data) {
      message = error.response?.data?.error;
    } else if (error.message) {
      message = error.message;
    }

    if (!!message) {
      setTimeout(() => {
        toast.dismiss();
        toast.error(message, {
          ...toastOptions,
          type: toast.TYPE.ERROR,

          icon: <ExclamationOctagon />,
          className: "toast-error",
        });
      }, 200);
    }
    throw error;
  }
);

export default instance;
// import axios from "axios";
// import { toast } from "react-toastify";

// const BASE_URL = `http://localhost:3001/api/v1`;

// const instance = axios.create({
//   baseURL: BASE_URL,

//   headers: {
//     "Content-Type": "application/json",
//     "cache-control": "no-cache",
//   },
// });
// // instance.defaults.withCredentials = true;

// instance.interceptors.request.use(
//   async (config) => {
//     return config;
//   },
//   (error) => {
//     return error;
//   }
// );

// instance.interceptors.response.use(
//   (response) => {
//     if (response.data.message) {
//       toast.dismiss();
//       toast.success(response.data.message, {
//         ...toastOptions,
//         className: "toast-message",
//       });
//     }
//     return response.data;
//   },
//   (error) => {
//     toast.dismiss();
//     if (error.response?.status === 401) {
//       localStorage.removeItem("access_token");
//       window.location.reload();
//     } else if (error.response?.data?.error_description) {
//       toast.error(error.response.data.error_description, {
//         toastId: error.response.data.error_description,
//       });
//     } else if (error.response?.data?.message) {
//       toast.error(error.response?.data?.message, {
//         toastId: error.response?.data?.message,
//       });
//     } else if (error.response?.data?.error) {
//       toast.error(error.response.data.error, {
//         toastId: error.response.data.error,
//       });
//     } else if (error.response?.data) {
//       toast.error(error.response?.data, {
//         toastId: error.response?.data,
//       });
//     } else if (error.message) {
//       toast.error(error.message, {
//         toastId: error.message,
//       });
//     }
//     throw error;
//   }
// );

// export default instance;
