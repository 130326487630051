import React, { Suspense } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import ProgressBar from "../components/TopBarProgress";
import ProtectedRoute from "./ProtectedRoute";
import AuthRoute from "./AuthRoute";
import useAuth from "../hooks/useAuthHook";
const Main = React.lazy(() => import("../modules/Main"));
const StreamContainer = React.lazy(() => import("../modules/stream/pages/StreamContainer"));
const AuthPage = React.lazy(() => import("../modules/auth/pages/AuthPage"));

// const ForgotPasword = React.lazy(() =>
//   import("../screens/Auth/ForgetPassword")
// );
// const ResetPassword = React.lazy(() => import("../screens/Auth/ResetPassword"));

// function router() {
//   const { isAuthenticated } = useAuth();

//   return (
//     <div className="App">
//       <Suspense fallback={<ProgressBar />}>
//         <BrowserRouter>
//           <Switch>
//             <AuthRoute path="/auth">
//               <AuthPage />
//             </AuthRoute>

//             <ProtectedRoute path="/dashboard" component={Main} />
//             <Route render={() => <Redirect to="/auth/login" />} />
//           </Switch>
//         </BrowserRouter>
//       </Suspense>
//     </div>
//   );
// }

// export default router;

export default function Index() {
  const { isAuthenticated } = useAuth();
  return (
    <>
      <React.Suspense fallback={<ProgressBar />}>
        {isAuthenticated ? (
          <>

            <Switch>
              <Route path={`/s`} component={StreamContainer} />
              <Route path="" component={Main}></Route>
            </Switch>
          </>
        ) : (
          <>
            <Switch>
              <Route path={`/s`} component={StreamContainer} />
              <Route path="/auth">
                <AuthPage />
              </Route>
              <Route render={() => <Redirect to="/auth/login"></Redirect>} />
            </Switch>
          </>
        )}
      </React.Suspense>
    </>
  );
}
