import {
  login as loginEmailPassword,
  logout as logoutServer,
} from "../modules/auth/service/api";
import { useContext } from "react";
import AuthContext from "../context/Auth";
import jwt_decode from "jwt-decode";
import { useMutation } from "@tanstack/react-query";

const TOKEN_KEY = "access_token";

export function getCurrentUser() {
  try {
    let decodeToken = jwt_decode(localStorage.getItem(TOKEN_KEY));
    return decodeToken;
  } catch (e) {
    return null;
  }
}

export default function useAuthHook() {
  let { user, setUser, isAuthenticated, setIsAuthenticated } =
    useContext(AuthContext);
  let { mutate: logoutMutate } = useMutation(logoutServer);
  // const hasPermission = (
  //   value,
  //   { checkAllScopes } = { checkAllScopes: false }
  // ) => {
  //   if (typeof value == "string") return user?.guardName?.includes(value);
  //   if (Array.isArray(value)) {
  //     if (checkAllScopes) {
  //       return value.every((element) => {
  //         return user?.guardName?.includes(element);
  //       });
  //     } else {
  //       return value.some((r) => user?.guardName?.includes(r));
  //     }
  //   }
  //   return false;
  // };
  // const getResourcePermission = (index) => {
  //   return user?.resourceScope?.[index];
  // };
  // const hasResourcePermission = (string) => {
  //   return user?.resourceScope?.includes(string);
  // };

  // const resetAccessToken = (newToken) => {
  //   try {
  //     localStorage.setItem(TOKEN_KEY, newToken);
  //     setUser(jwt_decode(newToken));
  //     setIsAuthenticated(true);
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };
  const login = async ({ email, password }) => {
    try {
      let data = await loginEmailPassword({
        email: email,
        password: password,
      });
      if (data.access_token) {
        localStorage.setItem(TOKEN_KEY, data.access_token);
        localStorage.setItem("user", JSON.stringify(data.user));
        setUser(jwt_decode(data.access_token));
        setIsAuthenticated(true);
      }
      return data;
    } catch (e) {
      throw e;
    }
  };

  const logout = async () => {
    // logoutMutate(
    //   {},
    //   {
    //     onSuccess: () => {
    localStorage.removeItem(TOKEN_KEY);
    setIsAuthenticated(false);
    //     },
    //   }
    // );
  };

  return {
    login,
    isAuthenticated,
    // resetAccessToken,
    //hasResourcePermission,
    // hasPermission,
    //getResourcePermission,
    user,
    logout,
  };
}
