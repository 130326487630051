import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import ProSidebar from "./providers/ProSidebarProvider";

const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(
//   <React.StrictMode>
//     <Suspense fallback={null}>
//       <ProSidebar>
//         <App />
//       </ProSidebar>
//     </Suspense>
//   </React.StrictMode>
// );
ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={null}>
      <ProSidebar>
        <App />
      </ProSidebar>
    </Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
