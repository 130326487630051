import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import ToastNotificationProvider from "./providers/ToastNotificationProvider";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import IsMutationProvider from "./providers/IsMutationProvider";
import AuthProvider from "./providers/AuthProvider";
// import socketInstance from "./socket";

// import { ThemeProvider } from "@mui/material/styles";
import "bootstrap/dist/css/bootstrap.min.css";

import Router from "./router";
import React from "react";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});
function App() {
  React.useEffect(() => {
    const u = localStorage.getItem("user");
    if (u) {
      // socketInstance.connect();
    }
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <IsMutationProvider>
        <ToastNotificationProvider>
          <AuthProvider>
            <div className="App">
              <BrowserRouter>
                <Router />
              </BrowserRouter>
            </div>
          </AuthProvider>
        </ToastNotificationProvider>
      </IsMutationProvider>
    </QueryClientProvider>
  );
}

export default App;
