import React from "react";
import TopBarProgress from "react-topbar-progress-indicator";

TopBarProgress.config({
  barColors: {
    0: "#145388",
  },
  shadowBlur: 5,
});

export default function Index() {
  return <TopBarProgress color={""} height={7} width={20} loading={true} />;
}
